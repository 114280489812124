import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FacilityClaimComponent = _resolveComponent("FacilityClaimComponent")!
  const _component_ProfessionalClaimComponent = _resolveComponent("ProfessionalClaimComponent")!

  return (_ctx.props.claim)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.props.claim.encounterType == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_FacilityClaimComponent, {
                claim: _ctx.props.claim,
                onClaimUpdated: _ctx.claimUpdated
              }, null, 8, ["claim", "onClaimUpdated"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_ProfessionalClaimComponent, {
                claim: _ctx.props.claim,
                onClaimUpdated: _ctx.claimUpdated
              }, null, 8, ["claim", "onClaimUpdated"])
            ]))
      ]))
    : _createCommentVNode("", true)
}