
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Claim } from "@/modules/claims/claim.model";
import FacilityClaimComponent from "@/modules/claims/FacilityClaimComponent.vue";
import ProfessionalClaimComponent from "@/modules/claims/ProfessionalClaimComponent.vue";

export interface Data {
  claim?: Claim;
}

export default defineComponent({
  name: "ClaimPage",
  props: ["claim"],
  components: { FacilityClaimComponent, ProfessionalClaimComponent },
  setup(props, ctx) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      claim: undefined,
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageBreadcrumbs("Claim Page", [
        {
          title: "Claim Management",
          path: "back",
          buttonTitle: "Back",
        },
      ]);
      data.claim = props.claim;
    });

    function claimUpdated(claim){
      ctx.emit("claimUpdated", claim);
    }

    return {
      organizationId,
      claimUpdated,
      props,
      data,
    };
  },
});
